<template>
  <div class="search-result">
    <searchList :collection = true></searchList>
  </div>
</template>

<script>
  import searchList from "../components/search-list.vue";
export default {

  name:'search',
  components:{
    searchList
  },
  data() {
    return {
    }
  },
}
</script>

<style lang="scss" scoped>
.search-result{
  padding-top: 74px;
}
@media screen and(max-width: 750px){
  .search-result{
    padding-top: 0.6rem;
  }
}
</style>
